// @ts-check
import { defineStore, acceptHMRUpdate } from 'pinia';
import { api } from '@/http/api';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { useQRCodeStore } from './qrcode';
import { useGameStore } from './game';

export const useSocketStore = defineStore('socket', {
  state: () => ({
    qrCodeStore: useQRCodeStore(),
    gameStore: useGameStore(),
    connection: undefined as HubConnection | undefined,
    connectionId: null as string | null | undefined,
    connectionState: null as HubConnectionState | null | undefined,
    clientConnections: [] as Array<any>
  }),
  getters: {},
  actions: {
    async initialize() {
      // TODO: Find a better way to handle this client query parameter. It would be better to pass it directly at the connection level.
      const response = await api.get(`/api/signalr/negotiate?client=${this.qrCodeStore.clientUuid}`);
      const { url, accessToken } = response.data;

      this.connection = new HubConnectionBuilder()
        .withUrl(url, {
          accessTokenFactory: () => accessToken
        })
        .configureLogging('debug')
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: (retryContext) => {
            return 10000;
          }
        })
        .build();

      this.subscribeMessages(this.connection);

      this.connection.onreconnecting(() => {
        console.log('SignalR reconnecting...');
        this.connectionState = this.connection?.state;
        this.cleanupConnection();
      });

      this.connection.onreconnected((connectionId) => {
        console.log(`SignalR reconnected on connectionId: ${connectionId}`);
        console.log(this.connection?.connectionId);

        this.connectionId = this.connection?.connectionId;
        this.connectionState = this.connection?.state;

        this.subscribeMessages(this.connection);
      });

      this.connection.onclose(() => {
        console.log('SignalR connection closed');

        this.cleanupConnection();
      });

      this.connection
        .start()
        .then(() => {
          console.log(
            'SignalR connection state:',
            this.connection?.state,
            ' - SignalR connection ID:',
            this.connection?.connectionId
          );
          this.connectionId = this.connection?.connectionId;
          this.connectionState = this.connection?.state;
        })
        .catch((err) => {
          console.error(err.toString());
        });
    },

    async subscribeMessages(connection: any) {
      connection.on('StartGame', (player: any) => {
        console.log('Received message: StartGame' + player.name);
        this.gameStore.startGame(player);
      });

      connection.on('StopGame', (player: any) => {
        console.log('Received message: StopGame' + player.name);
        this.gameStore.stopGame(player);
      });
    },

    async cleanupConnection() {
      // Example of clean-up logic
      console.log('Cleaning up resources...');
      this.connectionId = null;
    },

    async getClientConnections() {
      console.log('getting client connections');
      return await api.get('/api/signalr/connections').then((response) => {
        // process the response and store the connections
        this.clientConnections = response.data;
      });
    }
  }
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGameStore, import.meta.hot));
}
