const MainRoutes = {
  path: '/main',
  meta: {
    requiresAuth: true
  },
  redirect: '/main/dashboard/default',
  component: () => import('@/layouts/full/FullLayout.vue'),
  children: [
    {
      name: 'LandingPage',
      path: '/',
      component: () => import('@/views/home/home.vue')
    },
    {
      name: 'Home',
      path: '/home',
      component: () => import('@/views/home/home.vue')
    },
    {
      name: 'Levels',
      path: '/levels',
      component: () => import('@/views/levels/levels.vue')
    },
    {
      name: 'Themes',
      path: '/themes',
      component: () => import('@/views/themes/themes.vue')
    },
    {
      name: 'Kids',
      path: '/kids',
      component: () => import('@/views/players/List.vue')
    },
    {
      name: 'Groups',
      path: '/groups',
      component: () => import('@/views/groups/groups.vue')
    },
    {
      name: 'Employees',
      path: '/employees',
      component: () => import('@/views/employees/employees.vue')
    },
    {
      name: 'Planning',
      path: '/planning',
      component: () => import('@/views/planning/planning.vue')
    },

    {
      name: 'Default',
      path: '/dashboard/default',
      component: () => import('@/views/dashboards/default/DefaultDashboard.vue')
    },
    {
      name: 'Test',
      path: '/test',
      component: () => import('@/views/TestBoard.vue')
    },
    {
      name: 'Starter',
      path: '/starter',
      component: () => import('@/views/StarterPage.vue')
    },
    {
      name: 'MatchMachines',
      path: '/match-machines',
      component: () => import('@/views/clients/List.vue')
    },
    {
      name: 'Spelers',
      path: '/players',
      component: () => import('@/views/players/List.vue')
    },
    {
      name: 'Tabler Icons',
      path: '/icons/tabler',
      component: () => import('@/views/utilities/icons/TablerIcons.vue')
    },
    {
      name: 'Material Icons',
      path: '/icons/material',
      component: () => import('@/views/utilities/icons/MaterialIcons.vue')
    },
    {
      name: 'Typography',
      path: '/utils/typography',
      component: () => import('@/views/utilities/typography/TypographyPage.vue')
    },
    {
      name: 'Shadows',
      path: '/utils/shadows',
      component: () => import('@/views/utilities/shadows/ShadowPage.vue')
    },
    {
      name: 'Colors',
      path: '/utils/colors',
      component: () => import('@/views/utilities/colors/ColorPage.vue')
    }
  ]
};

export default MainRoutes;
